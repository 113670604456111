<template>
	<div class="container">
		<div class="detail-sec">
			<div class="title">{{info.title}}</div>
			<div class="time">发布时间：{{info.createTime}}</div>
			<div class="con" v-html="info.details"></div>
		</div>
	</div>
</template>

<script>
	import config from '../../config/config.js';
	import {
		Toast,
		ImagePreview,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css'
	import 'vant/lib/dialog/index.css'
	import 'vant/lib/index.css'
	export default {
		data() {
			return {
				id: '',
				info: {}
			}
		},
		methods: {
			getOperationsInfoByEntryId(productEntryId) {
				const that = this;
				let params = new FormData()
				params.append('pushMessageId', that.id)
				this.axios.post(config.requestUrl + '/search/getPushMessageDetails', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.info = response.data.obj;
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},

		},
		mounted() {
			this.id = this.$route.query.id;
			this.getOperationsInfoByEntryId();
		}
	}
</script>

<style scoped="scoped">
	.detail-sec {
		padding: 40px;
	}

	.title {
		font-size: 34px;
		font-weight: bold;
		color: #000000;
		line-height: 48px;
		margin-top: 40px;
	}

	.time {
		font-size: 24px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 24px;
		margin-top: 24px;
	}

	.con {
		width: 670px;
		margin: 50px auto 0;
	}

	.con>>>img {
		max-width: 100%;
	}

	.con>>>image {
		max-width: 100%;
	}
</style>
